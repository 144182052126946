<template>
    <div>
        <v-container>
            <s-crud
                :filter="filter"
				:config="config"
				add
				edit
				remove
				title="Columnas"
				height="auto"
                @save="save($event)"
            >
            <template scope="props">
                <v-row style="margin:auto">
                    <v-col lg="8" cols="12" class="pt-0">
                        <s-text
                            v-model="
                                props.item.ChcDescription
                            "
                            label="Descripción"
                        ></s-text>
                    </v-col>
                    <!-- <v-col lg="4" cols="12" class="pt-0">
                        <s-text
                            v-model="
                                props.item.ChpPosition
                            "
                            number
                            :min="0"
                            label="Posición"
                        ></s-text>
                    </v-col> -->
                </v-row>
            </template>
            </s-crud>
        </v-container>
    </div>
</template>
<script>

    import _sPrfChamberColumnService from "@/services/FreshProduction/PrfChamberColumnService";

    export default {
        props: {
            ChaID : {
				required: false,
				default: 0,
            }
        },
  		components: {  },
		data () {
			return { 
                filter: {},
                config: {
					model: {
						ChcID: "ID",
						SecStatus: "status",
					},
					service: _sPrfChamberColumnService,
					headers: [
                        { text: "ID", value: "ChcID" },
						{ text: "Descripción", value: "ChcDescription" },
						// { text: "Posicion", value: "ChcPosition" },
					],
				},
			}
		},
        methods:{
            save(item) {
                item.ChaID = this.ChaID;
                if(item.SecStatus == null)item.SecStatus = 1; 
                if (item.ChcDescription.length == 0) {
                    this.$fun.alert("Registre definición", "warning");
                    return;
                }
                item.save();
            },
        },
        mounted(){
            this.filter.ChaID = this.ChaID;
        }
    }
</script>